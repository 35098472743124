import axios from "axios";
export const config = {
    apiUrl: "https://www.impactlabs.app/"
};

const makeRequest = (url, extraParams) => {
    const options = {
        ...extraParams,
        headers: {
            ...(extraParams ? extraParams.headers : {})
        }
    };
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    return response.json().catch(() => "");
                }
                reject(response);
            })
            .then(response => {
                console.log("RESPONSE *****", response);
                return resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const postData = (resource, payload) =>
    makeRequest(config.apiUrl + resource, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

export const postPhpData = (resource, payload) =>
    makeRequest(resource, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });
export const putData = (resource, payload) => {
    return makeRequest(config.apiUrl + resource, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });
};

export const getData = resource => makeRequest(config.apiUrl + resource);

export const getPortfolio = data => postData("impact_points", data);

// const impactPontsAddRemove = "http://ec2-52-39-198-104.us-west-2.compute.amazonaws.com/api/portfolio/portfolio-engine"

export const setImpactPoint = data =>
    postData("api/portfolio/portfolio-engine", data);

export const getTickers = data =>
    postData("api/portfolio/portfolio-engine", data);

export const sendEmailNotification = data => {
    return axios
        .post("../../geoEmail.php", data)
        .then(function(response) {
            console.log("response", response);
        })
        .catch(function(error) {
            console.log(error);
        });
};
