import React from "react";
import {
    Slider
} from "antd";
import {
    iaText
} from "config/iaText";

export default ({
    callback,
    cause = {},
    step = {},
    onMouseUp
}) => {
    const sliderStep = (step && step[cause.causeId]) || {};

    function formatter(value) {
        const path = `${cause.causeCategory}.${cause.causeId}.${
            cause.causeWeight
        }`;
        const text = cause.causeWeight === 50 ? "I am neutral" : iaText()[path];
        return `${text}`;
    }
    return (
        <Slider
            tipFormatter = {formatter}
            min = {0}
            max = {4}
            step = {1}
            defaultValue = {
                sliderStep.hasOwnProperty('step') ? sliderStep.step : 2
            }
            // value={sliderStep.step}
            // onChange={step => callback(step, cause)}
            onAfterChange = {step => onMouseUp(step, cause)}
            dots
        />
    );
};
