import React, { Component } from "react";
import { connect } from "react-redux";
import { Collapse, Button, Icon, Modal } from "antd";
import { isEqual, isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import CustomScrollbars from "util/CustomScrollbars";
import { convertFromStep, convertToStep } from "util/helpers";
import SidebarLogo from "./SidebarLogo";
import texts from "config/texts.json";
import { getFromStorage, setInStorage } from "localStorageService";
import Auxiliary from "util/Auxiliary";
import Sliders from "../../components/Sliders";
import { iaText } from "config/iaText";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";

import {
    getAllCauses,
    storeSelectedCauses,
    getTickersData,
    getPortfolioData,
    storeSelectedCause,
    reset
} from "./actions";

import { sendEmailNotification } from "requestService";

const Panel = Collapse.Panel;
const confirm = Modal.info;

const defaultCauseID = iaText()["defaultCauseID"];
const defaultEnabledCause =
    defaultCauseID === "climateChange"
        ? {
              climateChange: {
                  category: iaText()[
                      "cause." + defaultCauseID + ".categoryLookup"
                  ],
                  step: 3
              }
          }
        : {
              women: {
                  category: iaText()[
                      "cause." + defaultCauseID + ".categoryLookup"
                  ],
                  step: 3
              }
          };
class SidebarContent extends Component {
    constructor(props) {
        super(props);
        const stepsFromStorage = getFromStorage("steps");
        this.state = {
            causes: [],
            step: stepsFromStorage,
            firstLoad: true
        };

        // this.setCauseToStore = debounce(this.setCauseToStore, 500);
        // this.getPortfolio = debounce(this.getPortfolio, 2000);
    }

    componentWillMount() {
        this.getCauses();
    }

    getCauses = () => {
        const stepsFromStorage = getFromStorage("steps");
        const hasNoValues = isEmpty(stepsFromStorage);

        if (!hasNoValues) {
            this.setState({
                ...this.state,
                step: stepsFromStorage
            });
        }
        this.props.getAllCauses();
    };

    async componentDidMount() {
        // setting up this to allow dynamic preset
        const defaultCauseID = iaText()["defaultCauseID"];
        const stepsFromStorage = getFromStorage("steps") || {};
        const getDefaultCause =
            (stepsFromStorage && stepsFromStorage.defaultCauseID) || {};
        const defaultCauseSetting =
            (getDefaultCause && getDefaultCause.step) || 3;
        const selectedCauseFromStorage = getFromStorage('selectedCauses') || {};
        debugger;
        await this.setCause(defaultCauseSetting, {
            causeCategory: iaText()[
            "cause." + defaultCauseID + ".categoryLookup"
                ],
            causeEnabled: true,
            causeId: defaultCauseID,
            causeLabel: iaText()["cause." + defaultCauseID + ".title"],
            causeVisible: true,
            causeWeight: 75
        });

        // if (!selectedCauseFromStorage) {
        //     const hasClimateChange = selectedCauseFromStorage.hasOwnProperty('climateChange');
        //     if (!hasClimateChange) {
        //         await this.setCause(defaultCauseSetting, {
        //             causeCategory: iaText()[
        //             "cause." + defaultCauseID + ".categoryLookup"
        //                 ],
        //             causeEnabled: true,
        //             causeId: defaultCauseID,
        //             causeLabel: iaText()["cause." + defaultCauseID + ".title"],
        //             causeVisible: true,
        //             causeWeight: 75
        //         });
        //     }
        // } else {
        //
        // }

        await Object.keys(selectedCauseFromStorage).forEach(key => {
            const cause = selectedCauseFromStorage[key];
            debugger;
            console.log('CAUSE NAME', cause);
            this.setCause(convertToStep(cause.causeWeight), cause);
        });

        this.getPortfolio();
        await this.getPortfolio();

        sendEmailNotification(this.props.sidebar.geo_ip);
    }

    componentWillReceiveProps(nextProps){
        const newTickerCauses = nextProps.sidebar.tickerCauses;
        const oldTickerCauses = this.props.sidebar.tickerCauses;
        const areEqual = isEqual(newTickerCauses, oldTickerCauses);
        const stepsFromStorage = getFromStorage("steps") || {};

        if (!areEqual) {
            this.setState({
                ...this.state,
                step: stepsFromStorage
            });
        }
        if (nextProps.sidebar.resetApp) {
            this.resetApp();
        }
    }

    componentDidUpdate(prevProps) {
        const { tickerCauses } = this.props.sidebar;
        const hasNewTickers = !isEqual(
            tickerCauses,
            prevProps.sidebar.tickerCauses
        );
        if (hasNewTickers) {
            setInStorage("returns", tickerCauses);
        }
    }

    getPortfolio = () => {
        const { tickerCauses, tickers } = this.props.sidebar;
        this.props.getTickersData(tickerCauses);
        this.props.getPortfolioData(tickerCauses, tickers);
    };

    getNoHeaderClass = navStyle => {
        if (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
        ) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    getNavStyleSubMenuClass = navStyle => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };

    setCauseToStore = cause => {
        this.props.storeSelectedCause(cause);
        this.props.storeSelectedCauses(cause);
    };

    setCause = (step, cause) => {
        debugger;
        cause.causeWeight = convertFromStep(step);
        this.setState(
            {
                ...this.state,
                step: {
                    ...this.state.step,
                    [cause.causeId]: {
                        step,
                        category: cause.causeCategory
                    }
                }
            },
            () => {
                setInStorage("steps", this.state.step);
            }
        );

        this.setCauseToStore(cause);
    };

    onMouseUp = (step, cause) => {
        this.setCause(step, cause);
        this.getPortfolio();
    };

    resetApp = async () => {
        await this.props.reset();
        await this.setState({
            ...this.state,
            step: {}
        });

        await setInStorage("steps", defaultEnabledCause);

        const defaultCauseID = iaText()["defaultCauseID"];
        await this.setCause(3, {
            causeCategory: iaText()[
                "cause." + defaultCauseID + ".categoryLookup"
            ],
            causeEnabled: true,
            causeId: defaultCauseID,
            causeLabel: iaText()["cause." + defaultCauseID + ".title"],
            causeVisible: true,
            causeWeight: 75
        });
        await this.getCauses();

        const tickerCauses = getFromStorage("returns");
        await this.props.getTickersData(tickerCauses);
        await this.getPortfolio();
    };

    showConfirm = (ev, cause) => {
        const textList = iaText();
        const text = ( window.innerWidth <= 414 ? '' : '<hr/>')
            + (textList["cards." + cause.causeId] || '');
        const iconUrl = texts["cause." + cause.causeId + ".icon"];
        confirm({
            title: cause.causeLabel,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                />
            ),
            onOk() {}
        });
    };

    render() {
        const { sidebar } = this.props;
        let headerNameTop;
        if (/(customsdg|xxxx)/.test(window.location.hostname)) {
            headerNameTop = "UN Sustainable Dev. Goals";
        } else if (/(veg|localxx)/.test(window.location.hostname)) {
            headerNameTop = "Popular Causes 🌱";
        } else {
            headerNameTop = "Popular Causes";
        }
        return (
            <Auxiliary>
                <SidebarLogo />
                <div className="gx-sidebar-content sliders-wrapper">
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <Collapse
                            className="causesSlider"
                            accordion
                            defaultActiveKey={["0"]}
                        >
                            {sidebar.popularCauses.length > 0 && (
                                <Panel header={headerNameTop}>
                                    {sidebar.popularCauses.map(
                                        (popularCause, index) => {
                                            return (
                                                <React.Fragment
                                                    key={`${index}_selected_slider`}
                                                >
                                                    <div
                                                        className="sliderTitle"
                                                        onClick={ev =>
                                                            this.showConfirm(
                                                                ev,
                                                                popularCause
                                                            )
                                                        }
                                                    >
                                                        <div className="labelForSlider">
                                                            {
                                                                popularCause.causeLabel
                                                            }
                                                        </div>
                                                        <div className="infoIcon">
                                                            <Icon type="info-circle" />
                                                        </div>
                                                    </div>
                                                    <Sliders
                                                        step={this.state.step}
                                                        cause={popularCause}
                                                        onMouseUp={(
                                                            step,
                                                            popularCause
                                                        ) =>
                                                            this.onMouseUp(
                                                                step,
                                                                popularCause
                                                            )
                                                        }
                                                        callback={(
                                                            step,
                                                            popularCause
                                                        ) =>
                                                            this.setCause(
                                                                step,
                                                                popularCause
                                                            )
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </Panel>
                            )}
                            {sidebar.causes &&
                                Object.keys(sidebar.causes).map(
                                    (category, index) => {
                                        const causes = sidebar.causes[category];
                                        const title = `causeGroup.${
                                            Object.values(
                                                sidebar.causes[category]
                                            )[0].causeCategory
                                        }.title`;
                                        const hasEnabledCauses = Object.values(
                                            causes
                                        ).filter(cause => cause.causeEnabled);
                                        return (
                                            hasEnabledCauses.length > 0 && (
                                                <Panel
                                                    header={texts[title]}
                                                    key={index + 1}
                                                >
                                                    {Object.keys(causes).map(
                                                        (item, indx) => {
                                                            const causeItem =
                                                                causes[item];
                                                            return (
                                                                <div
                                                                    key={`${indx}_selected_slider`}
                                                                    style={{
                                                                        display: causeItem.causeEnabled
                                                                            ? "block"
                                                                            : "none"
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="sliderTitle"
                                                                        onClick={ev =>
                                                                            this.showConfirm(
                                                                                ev,
                                                                                causeItem
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="labelForSlider">
                                                                            {
                                                                                causeItem.causeLabel
                                                                            }
                                                                        </div>
                                                                        <div className="infoIcon">
                                                                            <Icon type="info-circle" />
                                                                        </div>
                                                                    </div>
                                                                    <Sliders
                                                                        step={
                                                                            this
                                                                                .state
                                                                                .step
                                                                        }
                                                                        cause={
                                                                            causeItem
                                                                        }
                                                                        onMouseUp={(
                                                                            step,
                                                                            selectedCause
                                                                        ) =>
                                                                            this.onMouseUp(
                                                                                step,
                                                                                selectedCause
                                                                            )
                                                                        }
                                                                        callback={(
                                                                            step,
                                                                            selectedCause
                                                                        ) =>
                                                                            this.setCause(
                                                                                step,
                                                                                selectedCause
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </Panel>
                                            )
                                        );
                                    }
                                )}
                            {Object.values(sidebar.selectedCauses).length >
                                0 && (
                                <Panel header="Your Selected Causes">
                                    {Object.values(sidebar.selectedCauses).map(
                                        (popularCause, index) => {
                                            return (
                                                <React.Fragment
                                                    key={`${index}_selected_slider`}
                                                >
                                                    <div
                                                        className="sliderTitle"
                                                        onClick={ev =>
                                                            this.showConfirm(
                                                                ev,
                                                                popularCause
                                                            )
                                                        }
                                                    >
                                                        <div className="labelForSlider">
                                                            {
                                                                popularCause.causeLabel
                                                            }
                                                        </div>
                                                        <div className="infoIcon">
                                                            <Icon type="info-circle" />
                                                        </div>
                                                    </div>
                                                    <Sliders
                                                        step={this.state.step}
                                                        cause={popularCause}
                                                        onMouseUp={(
                                                            step,
                                                            popularCause
                                                        ) =>
                                                            this.onMouseUp(
                                                                step,
                                                                popularCause
                                                            )
                                                        }
                                                        callback={(
                                                            step,
                                                            popularCause
                                                        ) =>
                                                            this.setCause(
                                                                step,
                                                                popularCause
                                                            )
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </Panel>
                            )}
                        </Collapse>
                        <Button
                            onClick={this.resetApp}
                            type="primary"
                            size={"large"}
                            style={{
                                marginTop: "20px",
                                marginBottom: "100px",
                                marginLeft: "48px"
                            }}
                        >
                            Reset All
                        </Button>
                    </CustomScrollbars>
                    <br/><br/><br/><br/><br/><br/><br/>
                </div>
            </Auxiliary>
        );
    }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, sidebar }) => {
    const { navStyle, themeType, locale, pathname } = settings;
    return { navStyle, themeType, locale, pathname, sidebar };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAllCauses,
            storeSelectedCauses,
            getTickersData,
            getPortfolioData,
            storeSelectedCause,
            reset
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarContent);
