import {
    handleActions
} from "redux-actions";
import {
    GET_CAUSES_START,
    GET_CAUSES_SUCCESS,
    GET_CAUSES_FAIL,
    TICKERS_START,
    TICKERS_SUCCESS,
    TICKERS_FAIL,
    SET_CAUSE,
    SET_TICKER,
    IMPACT_POINTS_START,
    IMPACT_POINTS_SUCCESS,
    IMPACT_POINTS_FAIL,
    RESET_APP_DB_VERSION,
    RESET
} from "./constants";
import {
    convertToImpactPoint
} from "util/helpers";
import {
    getFromStorage,
    setInStorage,
    removeItem
} from "localStorageService";
import {
    iaText
} from "config/iaText";

const returnsFromLocalStorage = getFromStorage("returns") || [];
const selectedCausesFromLocalStorage = getFromStorage("selectedCauses") || {};
const currentCauseFromLocalStorage = getFromStorage("currentCause") || {};
const tickerCausesFromLocalStorage = getFromStorage("tickerCauses") || {};
const anchor = window.location.hash.split("/")[0];
const defaultCauseID = iaText()["defaultCauseID"];
console.log("anchor: ", anchor);


const initialState = {
    geo_ip: {
        ip: window.geoplugin_request(),
        city: window.geoplugin_city(),
        region: window.geoplugin_region(),
        areaCode: window.geoplugin_areaCode(),
        dmaCode: window.geoplugin_dmaCode(),
        countryCode: window.geoplugin_countryCode(),
        countryName: window.geoplugin_countryName(),
        continentCode: window.geoplugin_continentCode(),
        latitude: window.geoplugin_latitude(),
        longitude: window.geoplugin_longitude(),
        timezone: window.geoplugin_timezone(),

        anchor: anchor,

        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        language: navigator.language,
        onLine: navigator.onLine,
        platform: navigator.platform
            .replace("MacIntel", "Mac")
            .replace("Win32", "Win"),
        vendor: navigator.vendor,
        userAgent: navigator.userAgent,
        device: /Mobi/.test(navigator.userAgent) ? "Mobile" : "Desktop",
        windowInnerWidthHeight: window.innerWidth + "x" + window.innerHeight,
        websiteHref: window.location.href,
        websiteDomain: window.location.hostname.replace("www.", ""),
        websitePath: window.location.pathname
    },
    causes: [],

    isLoading: false,
    isSavingCauses: false,
    resetApp: false,
    globalReset: false,
    errorMessage: "",
    selectedCauses: selectedCausesFromLocalStorage,
    excluded: [],
    included: [],
    returns: returnsFromLocalStorage,
    currentCause: currentCauseFromLocalStorage,
    tickerCauses: tickerCausesFromLocalStorage || {},
    popularCauses: [{
        causeCategory: iaText()[
            "cause." + defaultCauseID + ".categoryLookup"
        ],
        causeEnabled: true,
        causeId: defaultCauseID,
        causeLabel: iaText()["cause." + defaultCauseID + ".title"],
        causeVisible: true,
        causeWeight: 75
    }],
    benchmark: {},
    hasTickers: false,
    hasImpactPoints: false
};

function recursiveUpdate(causes, selectedItems, categories) {
    const clonedCauses = causes;

    Object.keys(selectedItems).forEach(key => {
        const selectedItemCategory = selectedItems[key].causeCategory;
        const categoryIndex = categories.find(
            category => category.name === selectedItemCategory
        );
        clonedCauses[categoryIndex.id][key].causeWeight =
            selectedItems[key].causeWeight;
    });
    return clonedCauses;
}

function reorderCauses(categories, causes) {
    let clonedCauses = [];

    categories.forEach((cat, index) => {
        Object.keys(causes).forEach(key => {
            if (key === cat.name) {
                clonedCauses[cat.id] = causes[key];
            }
        });
    });
    return clonedCauses;
}

function findPopularCauses(allCauses) {
    let causes;
    if (/(sdg|xxx)/.test(window.location.hostname)) {
        causes = [{
                category: "sustainabilityCause",
                cause: "climateChange"
            },
            {
                category: "socialCause",
                cause: "women"
            },
            {
                category: "artCause",
                cause: "education"
            },
            {
                category: "peaceCause",
                cause: "slavery"
            },
            {
                category: "socialCause",
                cause: "poverty"
            },
            {
                category: "healthCause",
                cause: "health"
            },
            {
                category: "sustainabilityCause",
                cause: "altEnergy"
            },
            {
                category: "sustainabilityCause",
                cause: "waterConservation"
            }
        ];
    } else if (/(veg|xxx)/.test(window.location.hostname)) {
        causes = [{
                category: "sustainabilityCause",
                cause: "climateChange"
            },
            {
                category: "foodCause",
                cause: "vegan"
            },
            {
                category: "socialCause",
                cause: "women"
            },
            {
                category: "drugsCause",
                cause: "tobacco"
            },
            {
                category: "peaceCause",
                cause: "military"
            },
            {
                category: "socialCause",
                cause: "lgbt"
            },
            {
                category: "foodCause",
                cause: "sugar"
            },
            {
                category: "corporateCause",
                cause: "transparency"
            }
        ];
    } else if (/(psych|local)/.test(window.location.hostname)) {
        causes = [{
                category: "socialCause",
                cause: "humanRights"
            },
            {
                category: "sustainabilityCause",
                cause: "climateChange"
            },
            {
                category: "drugsCause",
                cause: "psychDrugs"
            },
            {
                category: "peaceCause",
                cause: "military"
            },
            {
                category: "peaceCause",
                cause: "slavery"
            },
            {
                category: "foodCause",
                cause: "sugar"
            },
            {
                category: "foodCause",
                cause: "vegan"
            },
        ];
    } else {
        causes = [{
                category: "sustainabilityCause",
                cause: "climateChange"
            },
            {
                category: "socialCause",
                cause: "women"
            },
            {
                category: "drugsCause",
                cause: "tobacco"
            },
            {
                category: "peaceCause",
                cause: "military"
            },
            {
                category: "socialCause",
                cause: "lgbt"
            },
            {
                category: "foodCause",
                cause: "sugar"
            },
            {
                category: "foodCause",
                cause: "vegan"
            },
            {
                category: "corporateCause",
                cause: "transparency"
            }
        ];
    }
    return causes.map(cause => allCauses[cause.category][cause.cause]);
}

export default handleActions({
        [GET_CAUSES_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_CAUSES_SUCCESS]: (state, payload) => {
            const selectPopularCauses = findPopularCauses(payload.causes);
            const causes = reorderCauses(payload.categories, payload.causes);
            return {
                ...state,
                causes: recursiveUpdate(
                    causes,
                    state.selectedCauses,
                    payload.categories
                ),
                popularCauses: selectPopularCauses,
                isLoading: false
            };
        },
        [GET_CAUSES_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),
        [TICKERS_START]: state => ({
            ...state,
            isLoading: true,
            hasTickers: false,
            globalReset: false
        }),
        [TICKERS_SUCCESS]: (state, data) => {
            const {
                returns,
                tickers,
                benchmark
            } = data.payload;
            setInStorage("returns", returns);
            return {
                ...state,
                tickers,
                returns,
                benchmark,
                isLoading: false,
                hasTickers: true,
                sector_breakdown: benchmark.Benchmark.sector_breakdown
            };
        },
        [TICKERS_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [IMPACT_POINTS_START]: state => ({
            ...state,
            hasImpactPoints: false,
            globalReset: false
        }),
        [IMPACT_POINTS_SUCCESS]: (state, data) => {
            return {
                ...state,
                excluded: data.payload.excluded,
                included: data.payload.included,
                hasImpactPoints: true
            };
        },
        [IMPACT_POINTS_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [SET_CAUSE]: (state, payload) => {
            const {
                cause
            } = payload;
            const selectedCauses = {
                ...state.selectedCauses,
                [cause.causeId]: cause
            };
            setInStorage("selectedCauses", selectedCauses);
            setInStorage("currentCause", cause);
            return {
                ...state,
                currentCause: cause,
                selectedCauses
            };
        },

        [SET_TICKER]: (state, payload) => {
            const {
                cause
            } = payload;
            const tickerCauses = {
                ...state.tickerCauses,
                [cause.causeId]: convertToImpactPoint(cause.causeWeight)
            };
            setInStorage("tickerCauses", tickerCauses);

            return {
                ...state,
                tickerCauses
            };
        },

        [RESET_APP_DB_VERSION]: state => ({
            ...state,
            resetApp: true
        }),

        [RESET]: (state, payload) => {
            removeItem("tickerCauses");
            removeItem("currentCause");
            removeItem("selectedCauses");
            removeItem("returns");
            removeItem("steps");
            // // for dev purposes
            // debugger;
            removeItem("modalOpened");
            // localStorage.clear();
            window.location = window.location.origin;

            return {
                ...state,
                causes: [],
                isLoading: false,
                isSavingCauses: false,
                resetApp: false,
                globalReset: false,
                errorMessage: "",
                selectedCauses: {},
                popularCauses: [{
                    causeCategory: iaText()[
                        "cause." + defaultCauseID + ".categoryLookup"
                    ],
                    causeEnabled: true,
                    causeId: defaultCauseID,
                    causeLabel: iaText()[
                        "cause." + defaultCauseID + ".title"
                    ],
                    causeVisible: true,
                    causeWeight: 75
                }],
                excluded: [],
                included: [],
                returns: [],
                currentCause: {},
                tickerCauses: {},
                tickers: [],
                benchmark: {},
                sector_breakdown: {}
            };
        }
    },
    initialState
);
