import React, { Component } from "react";
import { Layout, Modal, Button } from "antd";
import { Helmet } from "react-helmet";
import Fullscreen from "react-full-screen";
import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import { connect } from "react-redux";
import {
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { iaText } from "config/iaText";

const { Content, Footer } = Layout;
const emojiSDG = /Mobi/.test(navigator.userAgent) ? "🇺🇳" : "🌍";
const confirm = Modal.info;

export class MainApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        // this.openFirstVisitModal = debounce(this.openFirstVisitModal, 1000);
    }
    state = {
        open: false
    };
    getContainerClass = navStyle => {
        switch (navStyle) {
            case NAV_STYLE_DARK_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_DEFAULT_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_BELOW_HEADER:
                return "gx-container-wrap";
            case NAV_STYLE_ABOVE_HEADER:
                return "gx-container-wrap";
            default:
                return "";
        }
    };
    getNavStyles = navStyle => {
        switch (navStyle) {
            case NAV_STYLE_DEFAULT_HORIZONTAL:
                return <HorizontalDefault />;
            case NAV_STYLE_DARK_HORIZONTAL:
                return <HorizontalDark />;
            case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
                return <InsideHeader />;
            case NAV_STYLE_ABOVE_HEADER:
                return <AboveHeader />;
            case NAV_STYLE_BELOW_HEADER:
                return <BelowHeader />;
            case NAV_STYLE_FIXED:
                return <Topbar />;
            case NAV_STYLE_DRAWER:
                return <Topbar />;
            case NAV_STYLE_MINI_SIDEBAR:
                return <Topbar />;
            case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
                return <NoHeaderNotification />;
            case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
                return <NoHeaderNotification />;
            default:
                return null;
        }
    };

    getSidebar = (navStyle, width) => {
        if (width < TAB_SIZE) {
            return <Sidebar />;
        }
        switch (navStyle) {
            case NAV_STYLE_FIXED:
                return <Sidebar />;
            case NAV_STYLE_DRAWER:
                return <Sidebar />;
            case NAV_STYLE_MINI_SIDEBAR:
                return <Sidebar />;
            case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
                return <Sidebar />;
            case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
                return <Sidebar />;
            default:
                return null;
        }
    };

    renderMetaTags = () => {
        switch (true) {
            case /(sdg|xxx)/.test(window.location.hostname):
            case /(locast|xxx)/.test(window.location.hostname):
                return {
                    title:
                        "SDG " +
                        emojiSDG +
                        "ESG 🌎Sustainable Impact Investing ",
                    description:
                        "Sustainable investing 🌎 🇺🇳 SDGs, powered by IMPACT LABS: Empowering High Performance Wealth Management · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Empowering High Performance Wealth Management: Socially-Selective; Tax-Smart",
                    keywords:
                        "SDG, Sustainable Development Goals, Impact Labs, Sustainable Investing, Social Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Impact, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<h1 style='color: #5b92e5;'>Impact Labs SDG Optimizer</h1>Sustainable "+ emojiSDG +
                        " Tax-Smart Direct-Indexing<hr>We believe in the power of private capital to effect <b>positive change</b> and create a better tomorrow.<br><br>And we believe the best way to unleash that power is through <b>freedom of choice</b>.<br><br>Because <b>we believe in you</b>.<br><br><div style='background-color: #5b92e5; color: white; text-align:center; font-size: 120%; border-radius: 4px;'><div class='insideModalDiv'> <br>Creating a Better Tomorrow <br><span style='white-space:nowrap;'> ↔︎ <br>Freedom of Choice</span><br><br></div></div><br>We offer an unprecedented freedom of choice.<br><br>We empower you and your definition of responsible investing in a better tomorrow.<br><br><q>Swipe-right or swipe-left</q> on the causes or values that matter most to you, and our software takes care of the rest.<br><br><div style='text-align:center; '><big><b>Too good to be true?</b></big></div>"
                };
            case /(veg|xxx)/.test(window.location.hostname):
                return {
                    title:
                        "Vegan 🌱Sustainable 🌎Custom ESG " +
                        emojiSDG +
                        "SDG · Portfolio Optimizer",
                    description:
                        "Vegan 🌱 sustainable investing 🌎IMPACT LABS: Empowering High Performance Wealth Management · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Empowering High Performance Wealth Management: Socially-Selective; Tax-Smart",
                    keywords:
                        "vegan, Sustainable Investing, Social, Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<h1 style='color: #01A901;'>Welcome to Vegemize</h1>🌱 First-of-a-Kind Vegan Portfolio Optimizer<hr>At Impact Labs, we believe in the power of private capital to effect <b>positive change</b> and create a better tomorrow.<br><br>And we believe the best way to unleash that power is through <b>freedom of choice</b>.<br><br>Because <b>we believe in you</b><br><br><div style='background-color: #01A901; color: white; text-align:center; font-size: 120%; border-radius: 4px;'><div class='insideModalDiv'> <br>A Better Tomorrow <span style='white-space:nowrap;'> ↔︎ Freedom of Choice</span><br><br></div></div><br>We offer an unprecedented freedom of choice.<br><br>We empower you and your definition of responsible investing in a better tomorrow.<br><br><q>Swipe-right or swipe-left</q> on the causes or values that matter most to you, and our software takes care of the rest.<br><br><div style='text-align:center; '><big><b>Put your money where your mouth is...</b></big></div>"
                };
            case /(alpha|xxx)/.test(window.location.hostname):
            case /(esg|xxx)/.test(window.location.hostname):
            default:
                return {
                    title:
                        "Sustainable 🌎ESG·SDG " +
                        emojiSDG +
                        "Tax-Smart · Direct Indexing · Portfolio Optimizer",
                    description:
                        "Sustainable investing 🌎IMPACT LABS: Empowering High Performance Wealth Management · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Empowering High Performance Wealth Management: Socially-Selective; Tax-Smart",
                    keywords:
                        "Impact Labs, Sustainable Investing, Social Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Impact, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<div style='background-color: #3357ff; color: white; text-align:center; border-radius: 4px;'><div class='insideModalDiv'><big>Welcome to Impact Labs</big><br><small>Sustainable 🌎 ESG·SDG " +
                        emojiSDG +
                        " Optimizer</small><br>Personalized Direct-Indexing</div></div><br>We believe in the power of private capital to effect <b>positive change</b> and create a better tomorrow.<br><br>And we believe the best way to unleash that power is through <b>freedom of choice</b>.<br><br>Because <b>we believe in you</b>.<br><br><div style='background-color: #3357ff; color: white; text-align:center; font-size: 120%; border-radius: 4px;'><div class='insideModalDiv'>A Better Tomorrow <span style='white-space:nowrap;'> ↔︎ Freedom of Choice</span></div></div><br>We empower you and your definition of responsible investing in a better tomorrow.<br><br>We offer an unprecedented freedom of choice.<br><br><q>Swipe-right or swipe-left</q> on the causes or values that matter most to you, and our software takes care of the rest."
                };
        }
    };

    componentDidMount() {
        this.openFirstVisitModal();
    }

    openFirstVisitModal = () => {
        const alreadyOpened = localStorage.getItem("modalOpened");
        //debugger;
        if (!alreadyOpened) {
            this.setState({
                open: true
            });
        }
    };

    onClose = () => {
        this.setState(
            {
                open: false
            },
            () => {
                localStorage.setItem("modalOpened", true);
            }
        );
    };
    onCancel = () => {
        // disabled in the hopes user reads more of the Welcome Modal.
        console.log("onCancel tapped (disabled)");
    };

    showPerfModal = (ev) => {
        const textList = iaText();
        const text = textList["confirmModal.perf.html"];
        const modalTitle = textList["confirmModal.perf.title"];
        const iconUrl = textList["confirmModal.perf.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '<hr/>' + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    render() {
        const { match, width, navStyle } = this.props;
        const text = iaText();
        const {
            title,
            description,
            appName,
            keywords,
            modalText
        } = this.renderMetaTags();
        return (
            <Fullscreen enabled={false}>
                <Layout className="gx-app-layout">
                    {this.getSidebar(navStyle, width)}
                    <Layout>
                        {this.getNavStyles(navStyle)}
                        <Content
                            className={`gx-layout-content ${this.getContainerClass(
                                navStyle
                            )} `}
                        >
                            <Helmet>
                                <title>{title}</title>
                                <meta
                                    name="description"
                                    content={description}
                                />
                                <meta name="keywords" content={keywords} />
                                <meta
                                    name="application-name"
                                    content={appName}
                                />
                                <meta name="keywords" content={appName} />
                            </Helmet>
                            <App match={match} />
                            <Footer>
                                <div className="gx-layout-footer-content">
                                    {footerText}
                                </div>
                            </Footer>
                            <Modal
                                onCancel={this.onCancel}
                                visible={this.state.open}
                                closable={false}
                                footer={[
                                    <Button key="back" onClick={this.onClose}
                                        style={{background: '#038fde', color: 'white'}}>
                                        OK
                                    </Button>
                                ]}
                            >
                                <div
                                    className="headerModals"
                                    dangerouslySetInnerHTML={{
                                        __html: modalText
                                    }}
                                />
                                <br />
                                <div onClick={ev => this.showPerfModal( ev)}
                                    dangerouslySetInnerHTML={{
                                        __html: text["confirmModal.perf.imageTap"]
                                    }}
                                />

                            </Modal>
                        </Content>
                    </Layout>
                </Layout>
            </Fullscreen>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { width, navStyle } = settings;
    return { width, navStyle };
};
export default connect(mapStateToProps)(MainApp);
