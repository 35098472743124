import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "../reducers/index";
import {routerMiddleware} from "react-router-redux";
import createHistory from "history/createBrowserHistory";
import thunk from "redux-thunk";
import logger from 'redux-logger'

const initialState = {};
const enhancers = [];
export const history = createHistory();
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  applyMiddleware(logger),
  ...enhancers
);

export default createStore(rootReducer, initialState, composedEnhancers);
