import * as firebase from "firebase";
import { keys } from "./firebaseKeys";

const currentHref = window.location.hostname.replace("www.", "");
console.log(currentHref);
const firebaseKeys = keys(currentHref);
firebase.initializeApp(firebaseKeys);

export const databaseRef = firebase.database().ref();

export const version = databaseRef.child("version");
export const allCauses = databaseRef.child("allCauses");
export const defaultCategories = databaseRef.child("defaultCategories");
export const customCategories = databaseRef.child("customCategories");
export const defaultCausesSetup = databaseRef.child("defaultCausesSetup");
export const settings = databaseRef.child("settings");
