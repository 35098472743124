import React, { Component } from "react";
import { Layout, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import {
    switchLanguage,
    toggleCollapsedSideNav
} from "../../appRedux/actions/Setting";
import Auxiliary from "util/Auxiliary";
import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import { iaText } from "config/iaText";
import texts from "config/texts.json";

const { Header } = Layout;
const emojiSDG = /Mobi/.test(navigator.userAgent) ? '🇺🇳' : '🌍';
const confirm = Modal.info;

class Topbar extends Component {
    state = {
        searchText: "",
        openLabs: false,
        openTax: false,
        openIA: false,
        openSDG: false,
        openVeg: false,
    };

    componentDidMount() {
        this.props.toggleCollapsedSideNav(true);
    }

    openEqualityModal = () => {
        this.setState({
            openEquality: true
        });
    };
    goToEquality = () => {
        this.openNewTab("https://InvestForEquality.com#gotoEq");
        this.onCloseEqualityModal();
    };
    onCloseEqualityModal = () => {
        this.setState({
            openEquality: false
        });
    };

    openSDGModal = () => {
        this.setState({
            openSDG: true
        });
    };
    goToSDG = () => {
        this.openNewTab("https://CustomSDG.com#gotoSDG");
        this.onCloseSDGModal();
    };
    onCloseSDGModal = () => {
        this.setState({
            openSDG: false
        });
    };

    openVegModal = () => {
        this.setState({
            openVeg: true
        });
    };
    goToVeg = () => {
        this.openNewTab("https://vegemize.com#gotoVeg");
        this.onCloseVegModal();
    };
    onCloseVegModal = () => {
        this.setState({
            openVeg: false
        });
    };
    
    // openPerfModal = () => {
    //     this.setState({
    //         openPerf: true
    //     });
    // };
    // goToPerfLink = () => {
    //     this.openNewTab("https://impactlabs.com#perf");
    //     this.onClosePerfModal();
    // };
    // onClosePerfModal = () => {
    //     this.setState({
    //         openPerf: false
    //     });
    // };

    openLabsModal = () => {
        this.setState({
            openLabs: true
        });
    };
    goToImpactLabs = () => {
        this.openNewTab("https://impactlabs.com#gotoLabs");
        this.onCloseLabsModal();
    };
    onCloseLabsModal = () => {
        this.setState({
            openLabs: false
        });
    };

    openV1Modal = () => {
        this.setState({
            openV1: true
        });
    };
    goToV1 = () => {
        this.openNewTab("https://CustomESG.com#gotoCustomESG");
        this.onCloseV1Modal();
    };
    onCloseV1Modal = () => {
        this.setState({
            openV1: false
        });
    };

    openV2Modal = () => {
        this.setState({
            openV2: true
        });
    };
    goToV2 = () => {
        this.openNewTab("https://AlphaESG.com#gotoAlphaESG");
        this.onCloseV2Modal();
    };
    onCloseV2Modal = () => {
        this.setState({
            openV2: false
        });
    };

    openTaxModal = () => {
        this.setState({
            openTax: true
        });
    };
    goToTaxCalc = () => {
        this.openNewTab("https://taximpacts.com#goToTaxCalc");
        this.onCloseTaxModal();
    };
    onCloseTaxModal = () => {
        this.setState({
            openTax: false
        });
    };

    openIAModal = () => {
        this.setState({
            openIA: true
        });
    };
    goToIA = () => {
        this.openNewTab("https://impactadvisor.com#gotoIA");
        this.onCloseIAModal();
    };
    onCloseIAModal = () => {
        this.setState({
            openIA: false
        });
    };

    openNewTab = url => {
        window.open(url, "_self");
        // const win = window.open(url, "_blank");
        // win.focus();
    };

    showPerfModal = (ev) => {
        const textList = iaText();
        const text = textList["confirmModal.perf.html"];
        const modalTitle = textList["confirmModal.perf.title"];
        const iconUrl = textList["confirmModal.perf.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '<hr/>' + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    render() {
        const { width, navCollapsed, navStyle } = this.props;
        const text = iaText();
        return (
            <Auxiliary>
                <Header>
                    {navStyle === NAV_STYLE_DRAWER ||
                    ((navStyle === NAV_STYLE_FIXED ||
                        navStyle === NAV_STYLE_MINI_SIDEBAR) &&
                        width < TAB_SIZE) ? (
                        <div className="gx-linebar gx-mr-3">
                            <i
                                className="gx-icon-btn icon icon-menu"
                                onClick={() => {
                                    this.props.toggleCollapsedSideNav(
                                        !navCollapsed
                                    );
                                }}
                            />
                        </div>
                    ) : null}
                    <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
                        {/* <img alt="" src={require("assets/images/w-logo.png")} /> */}
                    </Link>
                    <div className="gx-header-notifications gx-ml-auto partnerLogos">
                        {(/(sdg|veg|equality|local)/.test(window.location.hostname)) ?
                            <div className="createLogoContainer createLogoText"
                                style={{background: '#3357ff'}}
                                alt="AlphaESG.com" title="AlphaESG.com"
                                onClick={this.openV2Modal}>  
                                {(window.innerWidth > 760) ? '🌎 AlphaESG ' : (window.innerWidth > 550) ? '🌎 ESG ' : '🌎'} 
                            </div> : null
                        }
                        {(/(veg|xxx)/.test(window.location.hostname)) ? null :
                            <div className="createLogoContainer createLogoText"
                                style={{background: '#01A901'}}
                                alt="Vegemize.com" title="Vegemize.com"
                                onClick={this.openVegModal}>  
                                {(window.innerWidth > 760) ? '🌱 Vegemize ' : (window.innerWidth > 550) ? '🌱 Veg ' : '🌱'} 
                        </div>
                        }
                        {(/(sdg|xxx)/.test(window.location.hostname)) ? null :
                            <div className="createLogoContainer createLogoText"
                                style={{background: '#5b92e5'}}
                                alt="CustomSDG.com" title="CustomSDG.com"
                                onClick={this.openSDGModal}>  <span role="img" aria-label="UN flag">{emojiSDG}</span>
                                {(window.innerWidth > 1051) ? ' Sustainable Dev. Goals ' : (window.innerWidth > 800) ? ' Sust. Dev. Goals ' :(window.innerWidth > 500) ? ' SDGs ' : ''} 
                            </div>
                        }
                        {(/(equality|xxx)/.test(window.location.hostname)) ? null :
                            <div className="createLogoContainer createLogoText"
                                style={{background: '#ff3a21'}}
                                alt="Vegemize.com" title="Vegemize.com"
                                onClick={this.openEqualityModal}>&nbsp;
                                <img src='https://impactstatic.s3-us-west-2.amazonaws.com/images/icons/png/genderEq_400x.png'
                                     className="invertImg"
                                    style={{padding:'0px 1px 2px 1px'}}
                                    alt="gender equality symbol" width="20" height="20"/>
                                {(window.innerWidth > 760) ? '🌈 Equality ' : (window.innerWidth > 550) ? '🌈 Eq ' : '🌈'} 
                            </div>
                        }
                        {(window.innerWidth > 375) ? '   ' : ''}&nbsp;
                        <div className="createLogoContainer" style={{background: '#3357ff'}}
                            alt="ImpactLabs.com" title="ImpactLabs.com"
                            onClick={this.openLabsModal}>
                            <div className="">
                                <img src='https://impactstatic.s3-us-west-2.amazonaws.com/images/impact/labs_logo_white-alpha400x.png'
                                    style={{padding:'0px 1px 2px 2px'}}
                                    alt="Impact Labs" width="20" height="auto"/>
                                {(window.innerWidth > 760) ? '' : (window.innerWidth > 550) ? '' : ''}
                            </div>
                        </div>
                        <div className="createLogoContainer" style={{background: '#2c7ec0'}}
                            alt="CustomESG.com" title="CustomESG.com"
                            onClick={this.openV1Modal}>
                                <img className="" style={{padding:'0px 1px 2px 2px'}}
                                src='https://impactstatic.s3-us-west-2.amazonaws.com/images/impact/labs_logo_white-alpha400x.png' alt="CustomESG.com"/>
                                {(window.innerWidth > 760) ? '' : (window.innerWidth > 550) ? '' : ''}
                        </div>
                        <div className="partnerLogoContainer">
                            <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/tax/android-chrome-192x192.png"
                            alt="TaxImpacts.com" title="TaxImpacts.com"
                            onClick={this.openTaxModal}
                        /></div>
                        <div className="createLogoContainer" style={{background: '#3357ff'}}
                            alt="ImpactAdvisor.com" title="ImpactAdvisor.com"
                            onClick={this.openIAModal}>
                                <img className="" style={{padding:'0px 0px 2px 3px'}}
                                src='https://impactstatic.s3-us-west-2.amazonaws.com/images/impact/IA_white_400x.png' alt="IA"/>
                                {(window.innerWidth > 760) ? '' : (window.innerWidth > 550) ? '' : ''}
                                <img src='https://d3e1j90z5g3six.cloudfront.net/images/other/A_BCorp_alpha_crop.png'
                                     className="bCorpImg"
                                    style={{padding:'0px 4px 3px 3px'}}
                                    alt="B Corp"/>
                        </div>
                    </div>
                </Header>
                <Modal
                    onCancel={this.onCloseV2Modal}
                    visible={this.state.openV2}
                    footer={[
                        <Button key="back" onClick={this.onCloseV2Modal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToV2}
                            style={{background: '#3357ff', color: 'white'}}>
                            <span role="img" aria-label="Earth">🌎</span>AlphaESG<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.v2"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.showPerfModal( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.perf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseSDGModal}
                    visible={this.state.openSDG}
                    footer={[
                        <Button key="back" onClick={this.onCloseSDGModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToSDG}
                            style={{background: '#5b92e5', color: 'white'}}>
                            {emojiSDG}CustomSDG<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.SDG"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseEqualityModal}
                    visible={this.state.openEquality}
                    footer={[
                        <Button key="back" onClick={this.onCloseEqualityModal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward" onClick={this.goToEquality}
                            style={{background: '#ff3a21', color: 'white'}}>
                            <img className="invertImg" src='https://impactstatic.s3-us-west-2.amazonaws.com/images/icons/png/genderEq_400x.png' width="20" alt="gender equality symbol"/>  InvestForEquality<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.equality"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.showPerfModal( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.perf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseVegModal}
                    visible={this.state.openVeg}
                    footer={[
                        <Button key="back" onClick={this.onCloseVegModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToVeg}
                            style={{background: '#01A901', color: 'white'}}>
                            <span role="img" aria-label="Vegemize">🌱</span>Vegemize<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals" 
                        dangerouslySetInnerHTML={{
                            __html: text["modal.veg"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.showPerfModal( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.perf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseLabsModal}
                    visible={this.state.openLabs}
                    footer={[
                        <Button key="back" onClick={this.onCloseLabsModal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward"
                            onClick={this.goToImpactLabs}
                            style={{background: '#3357ff', color: 'white'}}>
                            <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/labs/android-chrome-192x192.png"
                            width="20" alt="Impact Labs logo"/>  ImpactLabs<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.labs"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseV1Modal}
                    visible={this.state.openV1}
                    footer={[
                        <Button key="back" onClick={this.onCloseV1Modal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward" onClick={this.goToV1}
                            style={{background: '#2c7ec0', color: 'white'}}>
                            <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/labs2/android-chrome-192x192.png"
                            width="20" alt="Impact Labs logo"/>  CustomESG<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.v1"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseTaxModal}
                    visible={this.state.openTax}
                    footer={[
                        <Button key="back" onClick={this.onCloseTaxModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToTaxCalc}
                            style={{background: '#ee6e73', color: 'white'}}>
                            TaxImpacts<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.taxCalc"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseIAModal}
                    visible={this.state.openIA}
                    footer={[
                        <Button key="back" onClick={this.onCloseIAModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToIA}
                            style={{background: '#3357ff', color: 'white'}}>
                            ImpactAdvisor<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.IA"]
                        }}/>
                </Modal>
            </Auxiliary>
        );
    }
}

const mapStateToProps = ({ settings, sidebar }) => {
    const { locale, navStyle, navCollapsed, width } = settings;
    return { locale, navStyle, navCollapsed, width, sidebar };
};

export default connect(
    mapStateToProps,
    { toggleCollapsedSideNav, switchLanguage }
)(Topbar);
