export const keys = currentHref => {
    console.log("CURRENT HREF", currentHref);
    switch (true) {
        case /(local|xxx)/.test(window.location.hostname):
        case /(beta|beta.alphaesg)/.test(window.location.hostname):
            return {
                apiKey: "AIzaSyBb1DGO_MwwsHS-x2XoKQTEfLTtiYHubFk",
                authDomain: "betaalphaesg.firebaseapp.com",
                databaseURL: "https://betaalphaesg.firebaseio.com",
                projectId: "betaalphaesg",
                storageBucket: "betaalphaesg.appspot.com",
                messagingSenderId: "967510247940"
            };
        case /(sdg)/.test(window.location.hostname):
            return {
                apiKey: "AIzaSyCz2N4vpkR9Knz0u6c9ENsJr1OIJKRoObU",
                authDomain: "customsdg-b7b5d.firebaseapp.com",
                databaseURL: "https://customsdg-b7b5d.firebaseio.com",
                projectId: "customsdg-b7b5d",
                storageBucket: "customsdg-b7b5d.appspot.com",
                messagingSenderId: "905958488783"
            };
        case /(esg)/.test(window.location.hostname):
        default:
            return {
                apiKey: "AIzaSyCbfMzem7WqfjZrq2N6VMeh_cJztezgyNw",
                authDomain: "aslphaesg.firebaseapp.com",
                databaseURL: "https://aslphaesg.firebaseio.com",
                projectId: "aslphaesg",
                storageBucket: "aslphaesg.appspot.com",
                messagingSenderId: "69387786003"
            };
    }
};

// demo config

// apiKey: "AIzaSyDoLNhPOUavxGeNVtvoNOkwmSqJS-FwfHk",
// authDomain: "iasettingsdemo.firebaseapp.com",
// databaseURL: "https://iasettingsdemo.firebaseio.com",
// projectId: "iasettingsdemo",
// storageBucket: "iasettingsdemo.appspot.com",
// messagingSenderId: "513779731083"

// apiKey: "AIzaSyBKkR7uk4w6QdFtj706OlYjooShJIaIa64",
// authDomain: "customesg.firebaseapp.com",
// databaseURL: "https://customesg.firebaseio.com",
// projectId: "customesg",
// storageBucket: "customesg.appspot.com",
// messagingSenderId: "625455272639"

// apiKey: "AIzaSyCz2N4vpkR9Knz0u6c9ENsJr1OIJKRoObU",
// authDomain: "customsdg-b7b5d.firebaseapp.com",
// databaseURL: "https://customsdg-b7b5d.firebaseio.com",
// projectId: "customsdg-b7b5d",
// storageBucket: "customsdg-b7b5d.appspot.com",
// messagingSenderId: "905958488783"
